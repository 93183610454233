/*@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");*/
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

body {
  /*font-family: "DM Sans", sans-serif;*/
  font-family: 'Titillium Web', sans-serif;
}

.shadow {
  -webkit-filter: drop-shadow( 3px 3px 2px #d2d2d2);
  filter: drop-shadow( 3px 3px 2px #d2d2d2);
  /* Similar syntax to box-shadow */
}

.rotate60 {
  transform: rotate(-60deg);
}

.rotate90 {
  transform: rotate(-90deg);
}

.rotate10 {
  transform: rotate(-10deg);
}

.rotate20 {
  transform: rotate(20deg);
}

.rotate100 {
  transform: rotate(100deg);
}

.img {    
  transition-duration: 1s;
}

.img:hover{    
  transform: scale(1.4) rotate(30deg);
  transition-duration: 1s;
}

option {
  color: black;
}

.blockie {
  border-radius: 50%;
}

/* custom scrollbar */
.custom-scroll::-webkit-scrollbar {
  width: 18px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;
  border-radius: 30px;
  border: 7px solid transparent;
  background-clip: content-box;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #d2d2d2;
}